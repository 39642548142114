import React from 'react';
import { Link } from 'react-router-dom'
import { Menu } from '@progress/kendo-react-layout';
import { Popup } from '@progress/kendo-react-popup';
import treeDatacons from "../../css/thirdparty/jstree-themes/default/32px.png";
import { isModelPublished } from '../../requests/actionCreators';
import { KB_URLS } from '../../global-constants';
import { cloneDeep } from 'lodash';
import { createNodeUrl } from '../../requests/type-to-path';


/**
 * INDEX
 * ------------------------------------------------------------
 * # Node
 * ## Creating/Saving New Nodes
 * ## Nav Tree
 * # Detail Pages
 * # Views/Characteristics
 * # Drag and Drop
 * # Miscellaneous
 * ------------------------------------------------------------
 */

// --------------------------------------------------------------------------------------------------------------------------
// # Node
// --------------------------------------------------------------------------------------------------------------------------
/**
 * just an object that hold data
 */
export const SKAYL_GUIDS = Object.freeze({
  unit: "EAID_0C482F2C_A548_4cf1_B9AD_5086FD62C41B",
  measurementSystem: "EAID_0D6D5FAF_71A2_4452_B2F9_AB8F15C4D13F",
  measurementSystemAxis: "EAID_A60E666A_2E05_4c81_A7CB_BDE7B7C3A189"
})


/**
 * Checks if the variable is a JavaScript Object
 *    currently used to see if http response was parsed into an object
 *
 * @param {object} obj an object with the format of a model node
 * @return {boolean} Returns true if variable is an object, false otherwise
 */
 export const isJSON = (obj) => {
  return !!obj && !Array.isArray(obj) && typeof obj === 'object';
}

/**
 * Splits the xmiType by the colon and return a substring
 *
 * @param {string} xmiType a string in the format of "xmi:type"
 * @param {number} splitPos after splitting the string, specify which substring to return. defaults to the second substring
 * @return {string} Returns a substring of the xmiType
 */
 export function splitXmiType(xmiType, splitPos=2) {
  if (typeof xmiType !== 'string') {
    return "";
  }
  return xmiType.replace(/(.*):(.*)/, `$${splitPos}`);
}

/**
 * Separate a camel cased string with spaces
 *
 * @param {string} str a camel cased string
 * @return {string} Returns a new string with spaces
 */
export function splitCamelCaseWithSpace(str) {
  return str.replace(/([A-Z])/g, " $1").trim();
}

/**
 * 
 * @param {string} str 
 * @returns string that capatalizes the first character
 */

export function capFirstChar(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Sort nodes by XmiType and then by name
 *    used with JavaScript's sort method
 *
 * @param {object} node1 an object with the format of a model node
 * @param {object} node2 an object with the format of a model node
 * @return {number} Returns a -1, 0, or 1. Used as a comparison method for sort algorithms
 */
 export function sortNodesByType(node1, node2) {
  if (!node1?.xmiType || !node2?.xmiType) {
    return 0;
  }

  if (node1.xmiType === node2.xmiType) {
      const name1 = node1.name || node1.rolename || "";
      const name2 = node2.name || node2.rolename || "";

      if (name1.toLowerCase() < name2.toLowerCase()) return -1;
      if (name1.toLowerCase() > name2.toLowerCase()) return 1;
  } else {
      if (node1.xmiType < node2.xmiType) return -1;
      if (node1.xmiType > node2.xmiType) return 1;
  }
  return 0;
}

/**
 * Converts a xmiType string into a shortened substring containing all the string's characters following the colon.
 * The substring's characters will all be in camel case with the exception of consecutive uppercase characters.
 *
 * @param {string} xmiType a string in the format of "xmi:type"
 * @return {str} Returns a shortened string representation of the given xmiType.
 */
 export function getShortenedStringRepresentationOfXmiType(xmiType) {
  const type = splitXmiType(xmiType);
        const camelCaseXmiType = splitCamelCaseWithSpace(type);
        switch(type) {
            case "BoundedWString":
                return "Bounded WString";
            case "IDLArray":
                return "IDL Array";
            case "IDLStruct":
                return "IDL Struct";
            case "IDLComposition":
                return "IDL Composition";
            case "IDLSequence":
                return "IDL Sequence";
            case "UoPModel":
                return "UoP Model";
            case "UoPInstance":
                return "UoP Instance";
            case "UoPInputEndPoint":
                return "UoP Input End Point";
            case "UoPOutputEndPoint":
                return "UoP Output End Point";
            case "SIMAdapter":
                return "SIM Adapter";
            case "UoPInstanceToMainProgram":
                return "UoP Instance To Main Program";
            case "WChar":
            case "WCharArray":
            case "WString":
            case "ULong":
            case "ULongLong":
            case "UShort":
                return type;
            default: //not a special case;
        }
        return camelCaseXmiType;
 }

 /**
 * Converts a xmiType string into a shortened substring containing all the string's characters following the colon.
 * The substring's characters will all be in camel case with the exception of consecutive uppercase characters.
 *
 * @param {Object} char a node object with or without a viewType
 * @return {Boolean} Returns a boolean dependant on if the viewType is present.
 */
 export const isNestingChar = (char) => {
  return !!char.viewType;
}


// --------------------------------------------------------------------------------------------------------------------------
// ## Creating/Saving New Nodes
// --------------------------------------------------------------------------------------------------------------------------
/**
 * Generates a random set of characters base 36 (A-Z + 0-9)
 * Length of the string can go up to 12 characters
 * -> to lessen the string length, increase the "substr" number
 *
 * @param {number} times To further increase the string length and the randomness of characters
 * @return {string} A random set of characters, base 36
 */
export const randomChars = (times=1) => {
  const random = () => Math.random().toString(36).substr(2);
  return Array(times).fill(null).reduce((chars) => chars + random(), "");
}

/**
 * Used by smm-save-nodes to create a reference mapping of nested/sibling nodes
 *    randomTimes is initially set pretty high to reduce the likelihood of a guid collision
 *
 * @param {number} randomTimes To further increase the string length and the randomness of characters
 * @return {string} A random set of characters with the prefix "PHENOM-"
 */
export const createPhenomGuid = (randomTimes=4) => {
  return "PHENOM-" + randomChars(randomTimes);
}

/**
 * Used to differentiate between a real guid and "phenom" guid
 * note: hyphen is a valid character of a real guid. So there's a small chance that "PHENOM-" exists in a real guid
 *
 * @param {string} guid A string representation of a node's xmi:id
 * @return {boolean} Returns true if guid contains the prefix "PHENOM-", false otherwise
 */
export const isPhenomGuid = (guid) => {
  return typeof guid === 'string' && guid.startsWith("PHENOM-");
}


/**
 * Recursively convert object null values to undefined.
 *    smm-save-nodes will convert null to an empty string.  And empty strings are now legit values.
 *    by changing null to undefined, the backend will ignore its value.
 *
 * @param {object} data recursively loop through a javascript object and mutate null values into undefined
 * @return {object} mutated javascript object
 */
export const convertNullToUndefined = (data) => {
  // return undefined instead of null
  if (data === null) {
    return undefined;
  }

  // return data if it is a falsy value ("" or null)
  // return data if it is a primitive type
  if (!data || typeof data !== 'object') {
    return data;
  }

  // mutate the node by converting null into undefined
  // recursively loops through the object/array
  for (let key in data) {
    data[key] = convertNullToUndefined(data[key]);
  }

  return data;
}
/**
 * 
 * @param {object} node 
 * @returns object that has unecassary things removed such as null or undefined values and more
 */
export const cleanUpNodeForSmmSave = (node) => {
  // null is an 'object' and is falsy
  if (!node || Array.isArray(node) || typeof node !== 'object' || !Object.keys(node).length) {
    return null;
  }

  const copy = {...node};
  for (let key of Object.keys(copy)) {
    if (["guid", "xmiType", "name", "rolename", "children", "subModelId"].includes(key)) {
      continue;
    }

    const attr = copy[key];
    if (attr === undefined || attr === null) {
      delete copy[key];

    } else if (!!attr?.guid) {
      copy[key] = attr.guid;

    } else if (Array.isArray(attr) && attr.some(n => !!n?.guid)) {
      copy[key] = attr.map(n => n.guid);
    }
  }

  copy.children = [];
  return copy;
}

/**
 * Prepares nodes for SMM save by ensuring the input is an array.
 *
 * @param {Array} [nodes=[]] - The nodes to prepare.
 * @returns {Array} The prepared nodes array, or an empty array if the input is not an array.
 */

export const prepareNodesForSmmSave = (nodes=[]) => {
  if (!Array.isArray(nodes)) {
    return [];
  }

  const child_to_parent = {}
  const all_nodes = retrieveNestedNodes(nodes).filter(n => !isModelPublished(n?.subModelId));

  // assign parent guid to children nodes
  for (let node of all_nodes) {
    if (!node?.guid) continue;

    if (Array.isArray(node.children)) {
      node.children.forEach(c => {
        if (!c?.guid) return; // child must be an object
        child_to_parent[c.guid] = node.guid;
      })
    }
  }

  return all_nodes.map(n => {
    const parentGuid = child_to_parent[n.guid];
    const node = cleanUpNodeForSmmSave(n);

    if (parentGuid) {
      node.parent = parentGuid;
    }
    return node;
  });
}


// --------------------------------------------------------------------------------------------------------------------------
// ## Nav Tree
// --------------------------------------------------------------------------------------------------------------------------







// --------------------------------------------------------------------------------------------------------------------------
// # Detail Pages
// --------------------------------------------------------------------------------------------------------------------------

/**
 * Finds all nested nodes and flattens the array.
 *    Does not mutate the nodes.
 * 
 * @param {array} nodes 
 */
export const retrieveNestedNodes = (nodes) => {
  if (!Array.isArray(nodes)) {
    nodes = [];
  }

  const result = [];
  const stack = [ ...nodes ];
  while (stack.length) {
    const node = stack.pop();
    if (!node?.guid) continue;

    for (let key in node) {
      const attr = node[key];

      if (Array.isArray(attr)) {
        attr.forEach(ele => ele?.guid && stack.push(ele));

      } else if (attr?.guid) {
        stack.push(attr);
      }
    }

    result.push(node);
  }

  return result;
}


export const validateNodeFields = (requiredHash={}, nodeState={}) => {
  let valid = true;

  Object.keys(requiredHash).forEach(key => {
    const attr = nodeState[key];
    const { required, errorRef } = requiredHash[key];

    // checks "required" because detail page can conditionally turn it off
    // checks "errorRef.current" because the field can be conditionally rendered
    if (required && errorRef?.current && isInvalidField(attr)) {
      valid = false;
      errorRef.current.checkForErrors && errorRef.current.checkForErrors();
    }
  })

  return valid;
}

/**
 * Checks if a field is invalid based on various criteria.
 *
 * @param {*} attr - The attribute to check.
 * @returns {boolean} True if the attribute is considered invalid, false otherwise.
 */

export const isInvalidField = (attr) => {
  return (
    // check for falsy
    attr === undefined || attr === null ||

    // check for bad number
    (typeof attr === 'number' && isNaN(attr)) ||

    // check for empty string
    (typeof attr === 'string' && !attr) ||

    // check for empty array 
    (Array.isArray(attr) && !attr.length) ||

    // check for invalid nested node
    (typeof attr === 'object' && !Array.isArray(attr) && !(attr?.name || attr?.rolename))
  )
}

/**
 * Formats a raw date string into a more readable format.
 *
 * @param {string} dateRaw - The raw date string to format.
 * @param {boolean} includeTime - Whether to include time in the formatted output.
 * @returns {string} The formatted date string, or an empty string if the input is invalid.
 */

export const formatDate = (dateRaw, includeTime) => {
  if (!dateRaw || typeof dateRaw !== 'string') return "";
  const new_date = new Date(dateRaw);

  // invalid date
  if (isNaN(new_date)) {
    return "";
  }

  const config = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }

  if (includeTime) {
    config.hour = "2-digit";
    config.minute = "2-digit";
    config.second = "2-digit";
  }

  const dateFormatted = new Intl.DateTimeFormat("en-US", config).format(new_date);
  return dateFormatted;
}

export function createHopOptionTextForDropDown(hop, pathPairs=[]) {
  let depr = hop.deprecated === "true" ? "[Deprecated] " : "";

  if (hop.xmiType === "conceptual:Composition" || pathPairs.length === 0) {
    return `${depr}${hop.parent.name}:${hop.rolename}`;
  }

  if (hop["goingUp"]) {
    return `${depr}${hop.type.name} → ${hop.rolename}[${hop.parent.name}]`;
  }

  return `${depr}${hop.rolename} ← ${hop.parent.name}`;
}

export function stringifyPathPairs(pathPairs=[], includePathHead=true) {
  let pathText = "";

  let firstHop = pathPairs[0];
  if (includePathHead && firstHop) {
    const pathHead = firstHop.goingUp ? firstHop.type : firstHop.parent;
    pathText = pathHead?.name || "";
  }

  pathPairs.forEach(pair => {
    if (pair.goingUp) {
      pathText += `->${pair.rolename}[${pair.parent?.name}]`;
    } else {
      pathText += `.${pair.rolename}`;
    }
  })

  return pathText;
}

export function anchoredPathPairs(pathPairs=[]) {
  return pathPairs.map((pair, idx) => {
    let text = pair.goingUp ? `->${pair.rolename}[${pair.parent?.name}]` : `.${pair.rolename}`;
    const url = createNodeUrl(pair);

    if (url === "#") {
      return <span key={idx}>{text}</span>
    } else {
      return <Link key={idx}
                   to={url}
                   target="_blank">{text}</Link>
    }
  })
}





export function goingUpForwards(idx, pairs=[], projectedEntityGuid) {
  const currentHop = pairs[idx];
  const prevHop = pairs[idx - 1];

  if (currentHop.xmiType === "conceptual:Composition") {
    return false;
  }

  if (idx === 0) {
    return currentHop.type.guid === projectedEntityGuid;
  }

  const currentTypeGuid = currentHop.type.guid;
  const prevTypeGuid = prevHop.type.guid;

  if(currentTypeGuid !== prevTypeGuid) {
      return currentTypeGuid === prevHop.parent.guid;

  } else {
      if(prevHop.xmiType === "conceptual:Composition") {
          return true;
      } else {
          return !prevHop.goingUp;
      }
  }
}

export function modifyPathPairsForwards(pathPairs=[], projectedEntityGuid) {
  const copy = cloneDeep(pathPairs);

  // Assign goingUp
  copy.forEach((hop, idx) => {
    hop["goingUp"] = goingUpForwards(idx, copy, projectedEntityGuid);
    hop["text"] = createHopOptionTextForDropDown(hop, copy);
  })

  return copy;
}


export function goingUpBackwards(idx, pairs=[]) {
  const latestHop = pairs[idx];
  const sLatestHop = pairs[idx + 1];

  if (!latestHop || !sLatestHop || latestHop.xmiType === "conceptual:Composition") {
    return false;
  }

  const latestParentGuid = latestHop.parent?.guid;
  const latestTyperGuid = latestHop.type?.guid;
  const sLatestParentGuid = sLatestHop.parent?.guid;
  const sLatestTyperGuid = sLatestHop.type?.guid;

  if (!latestParentGuid || !sLatestParentGuid || !latestTyperGuid || !sLatestTyperGuid) {
    console.error("invalid path pair format");
    return false;
  }

  if (latestTyperGuid !== sLatestTyperGuid) {
    if (sLatestHop.xmiType === "conceptual:Composition") {  
      return latestParentGuid === sLatestParentGuid;
    } else {
      if (latestParentGuid === sLatestParentGuid) {
        return !goingUpBackwards(idx + 1, pairs);
      } else {
        return goingUpBackwards(idx + 1, pairs);
      }
    }
  } else {
    if (sLatestHop.xmiType === "conceptual:Composition") {
      return true;
    } else {
      return !goingUpBackwards(idx + 1, pairs);
    }
  }
}

export function modifyPathPairsBackwards(pathPairs=[]) {
  const copy = cloneDeep(pathPairs);

  // Assign goingUp
  copy.forEach((hop, idx) => {
    hop["goingUp"] = goingUpBackwards(idx, copy);
    hop["text"] = createHopOptionTextForDropDown(hop, copy);
  })

  return copy;
}







// --------------------------------------------------------------------------------------------------------------------------
// # Drag and Drop
// --------------------------------------------------------------------------------------------------------------------------
export const createTreeDragElement = (text_from="") => {
  const container = document.createElement("div");
        container.id = "dragging-leaf-ghosty";
        container.style.cssText = `
          display: flex;
          align-items: center;
          position: absolute;
          top: -100%;
          left: -100%;
          z-index: 1;
        `

  const imgIcon = document.createElement("div");
        imgIcon.id = "dragging-leaf-img";
        imgIcon.style.cssText = `
          background-image: url(${treeDatacons});
          background-position: 0 32px;
          width: 32px;
          height: 32px;
          overflow: hidden;
        `

  const wrapper = document.createElement("div");
        wrapper.style.cssText = `
          display: flex;
          gap: 5px;
          padding: 3px;
          font-size: 14px;
          color: "#404040";
          border: 1px solid lightgray;
          background: hsl(0, 0%, 100%);
        `

  const textFrom = document.createElement("span");
        textFrom.id = "dragging-leaf-from";
        textFrom.innerHTML = text_from;

  const textSymbol = document.createElement("span");
        textSymbol.id = "dragging-leaf-symbol";
        textSymbol.innerHTML = ">>";

  const textTo = document.createElement("span");
        textTo.id = "dragging-leaf-to";

  wrapper.appendChild(textFrom);
  wrapper.appendChild(textSymbol);
  wrapper.appendChild(textTo);
  container.appendChild(imgIcon);
  container.appendChild(wrapper);
  document.body.appendChild(container);
  return container
}


/**
 * Creates a html element and appends it to the body.
 * This is used to create a custom "ghost image" when performing onDragStart.
 * -> in short - on drag start will capture a screenshot on the dragging element but it needs to exist on the page.
 *    this method places the newly created element offscreen so it's visible to the browser but not to the user
 *
 * recommendation: clean up the newly created element to not create any side effects and to not add more clutter the DOM
 *
 * @param {object} data contains properties such as "name" and "background-color"
 * @return {object} Returns an object containing the HTMLElement and offset values
 */
export const createGhostElement = (data) => {
  const container = document.createElement("div");
        container.className = "node-container";
        container.style.cssText = `
          --nodeColor: ${data.bgColor};
          position: absolute;
          top: -100%;
          left: -100%;
        `

  const header = document.createElement("div");
  const headerText = document.createElement("span");
        header.className = "node-header";
        headerText.innerHTML = data.name;
        header.appendChild(headerText);
        container.appendChild(header);

  switch(data.type) {
    case "conceptual:Entity":
    case "conceptual:Association":
    case "platform:View":
      container.className += " node-font-color";
      header.className += " node-color-background";

      const attributes = document.createElement("div");
            attributes.className = "attribute-table";
            container.appendChild(attributes);

      break;

    case "im:UoPInstance":
      container.className += " node-color-border block-container";
      break;

    // Block Elements (IDM Editor)
    default:
      container.className += " node-color-background node-color-border block-container";
      header.className += " node-font-color";
  }

  document.body.appendChild(container);

  const widthPx = getComputedStyle(container,null).getPropertyValue('width');
  const width = parseInt(widthPx, 10);
  const headerHeight = 18;

  return {
    htmlElement: container,
    offset: [width / 2, headerHeight],
  }
}










// --------------------------------------------------------------------------------------------------------------------------
// # Miscellaneous
// --------------------------------------------------------------------------------------------------------------------------
export function stopBubbleUp(e) {
  e?.stopPropagation && e.stopPropagation();
}

/**
 * Scrubs a node object by removing null or empty values (except for the "path" key),
 * and replacing the "parent" key's object value with its "guid" property.
 *
 * @param {Object} node - The node object to scrub.
 */

export function scrubNodeExport(node) {
  Object.keys(node).forEach((key, i) => {
    const value = node[key];
    if (value === null || (value === "" && key !== "path")) {
      delete node[key];
    }else if (key === "parent" && typeof value === 'object') {
      node["parent"] = node["parent"]["guid"]
    } else if (typeof value === 'object') {
      scrubNodeExport(value)
    }
  })
}

/**
 * Converts string representations of booleans ("true" and "false") to actual boolean values in an object.
 *
 * @param {Object} obj - The object to scrub.
 * @returns {Object} The scrubbed object with boolean strings converted to boolean values.
 */

export function scrubBooleanStrings(obj) {
  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      // If the value is an object, recurse into it
      scrubBooleanStrings(obj[key]);
    } else if (obj[key] === "true") {
      obj[key] = true;
    } else if (obj[key] === "false") {
      obj[key] = false;
    }
  });
  return obj;
}

/**
 * Converts an array of objects into an object keyed by the `guid` property of each object.
 *
 * @param {Array<Object>} array - The array of objects to process.
 * @returns {Object} An object where each key is a `guid` from the array and each value is the corresponding object.
 */

export function deGuidify(array) {
    const finalList = {};
    Array.isArray(array) && array.forEach(ele => {
      if (!ele?.guid) return;
      finalList[ele.guid] = ele
    });
    return finalList;
}

export function arraysEqual(a, b) {
    if (a === b) return true;
    if (a === null && b === null) return true;
    if (a.length !== b.length) return false;

    for (const i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}

// Used with javascript's sort method to sort an array of nodes
// Ex: [node, node, node].sort((a,b) => sortNodesByName(a, b))
/**
 * Compares two node objects by their `name` properties for sorting.
 *
 * @param {Object} a - The first node to compare.
 * @param {Object} b - The second node to compare.
 * @returns {number} -1 if `a` should come before `b`, 1 if `a` should come after `b`, 0 if they are equal or invalid.
 */

export function sortNodesByName (a, b) {
    if (typeof a?.name !== 'string') return 0;
    if (typeof b?.name !== 'string') return 0;
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    return 0;
}

/**
 * 
 * @param {string} color starting hex color that needs brightening
 * @param {number} percent how much you want to brighten
 * @returns String
 */
export const LightenColor = (color, percent) => {
  if (color.startsWith("#")) color = color.substr(1);

  var num = parseInt(color,16),
    amt = Math.round(2.55 * percent),
    R = (num >> 16) + amt,
    B = (num >> 8 & 0x00FF) + amt,
    G = (num & 0x0000FF) + amt;

    return "#" + (0x1000000 + (R<255?R<1?0:R:255)*0x10000 + (B<255?B<1?0:B:255)*0x100 + (G<255?G<1?0:G:255)).toString(16).slice(1);
};


export const colors = {
    "face:LogicalDataModel": "#b15050",
    "conceptual:Observable": "#d34b19",
    "logical:MeasurementAxis": "#afaf40",
    "logical:MeasurementSystem": "#367587",
    "logical:StandardMeasurementSystem": "#367587",
    "logical:MeasurementSystemAxis": "#489bb3",
    "logical:CoordinateSystem": "#3c3267",
    "logical:CoordinateSystemAxis": "#5e4fa2",
    "logical:Landmark": "#5062bc",
    "logical:ReferencePoint": "#8aa5b2",
}


// deprecated - use primitiveTypeList from global-constants.js
export const primitiveTypes = {
  "platform:Boolean": "Boolean",
  "platform:Char": "Char",
  "platform:WChar": "Wide Char",
  "platform:String": "String",
  "platform:WString": "Wide String",
  "platform:Double": "Double",
  "platform:LongDouble": "Long Double",
  "platform:Float": "Float",
  "platform:Long": "Long",
  "platform:LongLong": "Long Long",
  "platform:ULong": "Unsigned Long",
  "platform:ULongLong": "Unsigned Long Long",
  "platform:Short": "Short",
  "platform:UShort": "Unsigned Short",
  "platform:Octet": "Octet",
  "platform:Enumeration": "Enumeration",
  "platform:Fixed": "Fixed",
  "platform:CharArray": "Char Array",
  "platform:WCharArray": "Wide Char Array",
  "platform:IDLStruct": "IDL Struct",
  "platform:IDLArray": "IDL Array",
  "platform:IDLSequence": "IDL Sequence",
  "platform:BoundedString": "Bounded String",
  "platform:BoundedWString": "Bounded Wide String",
}




export const primitives = {
    Boolean: "Boolean",
    Octet: "Octet",
    Char: "Char",
    WChar: "Wide Char",
    String: "String",
    WString: "Wide String",
    CharArray: "CharArray",
    Short: "Short",
    UShort: "Unsigned Short",
    Long: "Long",
    ULong: "Unsigned Long",
    LongLong: "Long Long",
    ULongLong: "Unsigned Long Long",
    Float: "Float",
    Double: "Double",
    LongDouble: "Long Double",
    IDLArray: "Array",
    BoundedString: "BoundedString",
    IDLSequence: "Sequence",
    Fixed: "Fixed",
    Enumeration: "Enumeration",
}

export const constraintsToPrimitives = {
    RegularExpressionConstraint: ["Char", "String", "WChar", "WString"],
    RealRangeConstraint: ["Double", "Float", "LongDouble"],
    IntegerRangeConstraint: ["Octet", "UShort", "ULong", "Short", "Long", "ULongLong", "LongLong"],
}


export const defaultBounds = {
  "lowerBound": "1",
  "upperBound": "1",
  "sourceLowerBound": "0",
  "sourceUpperBound": "-1",
}


export const boundedPrimitives = {
    Fixed: ["digits", "scale"],
    BoundedString: ["maxLength"],
    CharArray: ["length"],
    IDLArray: ["size"],
    IDLSequence: ["maxSize"],
}

/**
 * Filters and sorts two nodes based on a given filter string.
 *
 * @param {Object} node1 - The first node to compare.
 * @param {Object} node2 - The second node to compare.
 * @param {string} [filter=""] - The filter string to match against node names.
 * @returns {number} Sorting value indicating order of nodes.
 */

export const filterDataList = (node1, node2, filter="") => {
    if (typeof filter !== 'string') return 0;
    if (typeof node1?.name !== 'string') return 0;
    if (typeof node2?.name !== 'string') return 0;

    const cleanRegex = filter.match(/[\d\w_]*/)[0];
    const regex = new RegExp(cleanRegex, 'i');

    if(filter) {
        const match1 = node1.name.match(regex);
        const match2 = node2.name.match(regex);

        if(match1 && match2) {
            return sortNodesByName(node1, node2);
        } else if (match1) {
            return -1;
        } else if (match2) {
            return 1;
        } else {
            return 0;
        }
    } else {
        return sortNodesByName(node1, node2)
    }
}

/**
 * Generates a date-time string formatted for use in a filename.
 *
 * @returns {string} The formatted date-time string.
 */

export const getDateTimeForFilename = () => {
  const date = new Date();
  const config = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  }
  const dateFormatted = new Intl.DateTimeFormat("en-US", config).format(date);
  return dateFormatted.replace(/(\d*)\/(\d*)\/(\d*), (\d*):(\d*):(\d*).*/, "$3$2$1_$4$5$6");
}


export class ContextMenu extends React.Component {
  constructor(props) {
    super(props);
    ContextMenu.__singleton = this;
  }

  state = {
      visible: false,
      menuItems: [],
      offset: {},
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.visible !== this.state.visible) {
      if (this.state.visible) {
        window.addEventListener("click", this.handleOutsideClick);
      } else {
        window.removeEventListener("click", this.handleOutsideClick);
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.handleOutsideClick);
  }

  static show = (menuItems, offset) => {
    const that = ContextMenu.__singleton;
    const {selectedLeaf, prevSelectedLeaf} = that.state;
    that.setState({ 
      visible: true,
       menuItems, 
       offset, 
      });
  }

  static close = () => {
    const that = ContextMenu.__singleton;
    that._close();
  }

  _close = () => {
    this.setState({ visible: false });
  }

  handleOutsideClick = (e) => {
    if (!this.ctxMenuRef) return;
    const isOpen = this.state.visible;
    const clickedOutside = !this.ctxMenuRef.contains(e.target);
    const prevSelectedLeaf= this.state?.prevSelectedLeaf;
    const selectedLeaf = this.state?.selectedLeaf;
    const clickedAnotherLeaf = selectedLeaf !== prevSelectedLeaf;
    if (isOpen && clickedOutside || isOpen && clickedAnotherLeaf) {
      this._close();
    }
  }

  render() {
      return(
        <Popup show={this.state.visible}
               offset={this.state.offset}>
          <div ref={el => this.ctxMenuRef = el}>
            <Menu vertical={true}
                  style={{ display: 'inline-block' }}
                  items={this.state.menuItems}
                  onSelect={(e) => {
                    const { item } = e;
                    item.func && item.func();
                    !item.dontCloseMenu && this._close();
                  }}
                  itemRender={(element, itemProps) => {
                    const { itemId, item } = element;
                    
                    switch (item.type) {
                      case "colorInput":
                        return <label style={{
                                  fontWeight: 400,
                                  margin: 0,
                                  cursor: "pointer",
                                }}
                                id={item.id}>
                                  {item.text}
                                  <input type="color"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                        }}
                                        onChange={(e) => {
                                          item.colorChange(e.target.value);
                                        }}
                                        style={{ display: "none" }}/>
                                </label>
                      default:
                        return <span id={item.id}>
                            {item.text}
                          </span>
                    }}} />
          </div>
        </Popup>
    )
  };
}

export const getKbUrl = (params) => {
  const { mode, main_page, sub_page} = params;
  let url;

  if (sub_page) {
    url = KB_URLS[sub_page];
  } else {
    url = KB_URLS[main_page];
  }

  return url;
}
