/**
 * INDEX
 * ------------------------------------------------------------
 * 01. Life Cycle Methods
 * 02. Setter Methods
 * 03. Action Methods
 * 04. Misc
 * 05. Render Methods
 * ------------------------------------------------------------
 */

import React from "react";
import { connect } from "react-redux";
import { PhenomLabel } from "../../util/stateless";
import { ManageSubMenu } from "./model_manage";
import NavTree from "../../tree/NavTree";
import * as actionCreators from "../../../requests/actionCreators";
import { _ajax, editCommit, rollbackToCommit } from "../../../requests/sml-requests";
import loadingIcon from "../../../images/Palette Ring-1s-200px.gif";
import { KbButton } from "../../util/stateless";
import ListPaneView from "../../edit/list-pane-view";
import { saveNewCommit, fetchCommits } from "../../../requests/sml-requests";
import { formatDate } from "../../util/util";
import { BasicConfirm } from "../../dialog/BasicConfirm";
import { BasicAlert } from "../../dialog/BasicAlert";
import { validateNodeFields } from "../../util/util";
import { PhenomInput, PhenomTextArea } from "../../util/stateless";
import { NoSpecialCharsRegex } from "../../../hooks/useInputError";

export class Commit extends React.Component {
  
  listViewRef = React.createRef();
  
  constructor(props) {
    super(props);

    this.newCommit = {
      name: "",
      description: "",
    }

    this.state = {
      commits: [],
      loading: true,

      isNewCommit: true,
      activeCommit: {...this.newCommit},
      originalCommit: {...this.newCommit},
    };

    this.requiredFields = {
      ["name"]: {
        required: true,
        checkFirstChar: true,
        regexPattern: NoSpecialCharsRegex,
        errorRef: React.createRef(),
      },
    }
  }

  // ------------------------------------
  // 01. Life Cycle Methods
  // ------------------------------------
  componentDidMount() {
    NavTree.collapseNavTree(false);
    this.initState();
  }

  componentDidUpdate(prevProps, prevState) {
  }

  componentWillUnmount() {
  }


  // ------------------------------------
  // 02. Setter Methods
  // ------------------------------------
  initState = (commit = false) => {
    const { activeProjectId } = this.props.userIdentity;

    fetchCommits(activeProjectId).then(res => {
      if (!res?.data) return;
      const commits = this.formatCommits(Object.values(res.data));

      this.setState({commits: commits}, () => {
        if (commit) {
          const selectCommit = commits.find(commit1 => commit1.id === commit.id); // fix this, response return newly saved commit data
          selectCommit && this.setActiveCommit(selectCommit, true);
        }
      });
    }).always(() => {
      this.setLoading(false);
    });
  }

  setActiveCommit = (newCommit, preventClear=false) => {
    const { activeCommit } = this.state;
    if (!newCommit) return;

    // new active commit is selected - set active
    if ((newCommit.id !== activeCommit?.id) || preventClear) {
      this.setState({
        activeCommit: {...newCommit},
        originalCommit: newCommit,
        isNewCommit: false,
      });
    // same active commit is selected - set new
    } else {
      this.handlePlus();
    }
  }

  updateActiveCommit = (key, value) => {
    this.setState(prevState => ({
        activeCommit: {
            ...prevState.activeCommit,
            [key]: value
        }
    }));
  }

  setLoading = (bool) => {
    this.setState({loading: bool});
  }

  
  // ------------------------------------
  // 03. Action Methods
  // ------------------------------------
  handleSave = () => {
    if (!this.listViewRef.current) return;
    if (!this.validateFields()) return actionCreators.receiveWarnings("Please fill in the missing fields.");

    const { activeCommit, isNewCommit } = this.state;
    const saveFunction = isNewCommit ? saveNewCommit : editCommit;
    const saveCommit = {
      commitId: activeCommit.id,
      ...activeCommit,
    }

    BasicAlert.show(`Saving '${activeCommit.name}'`, "Processing request", false);

    saveFunction(saveCommit).then((res) => {
      if(!activeCommit.id && res?.data?.commitId) {
        activeCommit.id = res.data.commitId;
      }
      actionCreators.receiveResponse(res);
      this.initState(activeCommit);
    }).always(() => {
      BasicAlert.hide();
    });
  }

  handlePlus = () => {
    this.setState({
      activeCommit: {...this.newCommit},
      originalCommit: this.newCommit,
      isNewCommit: true,
    });
  }

  handleRollback = () => {
    const { commits, activeCommit, isNewCommit } = this.state;
    if (!this.listViewRef.current || isNewCommit) return actionCreators.receiveWarnings(["Please select or create a commit first."]);
    const commitId = activeCommit.id;
    const commitIndex = commits.findIndex(item => item.id === commitId);
    const itemsBeforeCommit = commitIndex ? [...commits].slice(0, commitIndex) : false;

    BasicConfirm.show(
      (itemsBeforeCommit && itemsBeforeCommit.length > 0) ?  
        "Rolling back to commit '" + activeCommit.name + "' will delete the following commits: " + 
        itemsBeforeCommit.map(item => "\n- " + item.name).join('') + 
        "\n\nThis action cannot be reversed. Are you sure you want to rollback?" :
        "Rolling back to commit '" + activeCommit.name + "'." + 
        "\n\nThis action cannot be reversed. Are you sure you want to rollback?",
      () => { 
        this.setLoading(true);
        rollbackToCommit(commitId).then((res) => {
          NavTree.reset();
          window["resetCache"] && window["resetCache"]();   // remove this after window variables are refactored
          actionCreators.receiveResponse(res);
          this.initState();
        }).always(() => {
          this.setLoading(false);
        });
      },
      () => {},
      "Warning"
    );
  }


  // ------------------------------------
  // 04. Misc
  // ------------------------------------
  formatCommits = (commits) => {
    let newCommits = [...commits];

    // sort raw time by descending order (newest first)
    newCommits = newCommits.sort((a, b) => {
      const dateA = new Date(a.created);
      const dateB = new Date(b.created);
      return dateB - dateA;
    });

    // format dates
    commits.forEach(commit => {
      commit.created = formatDate(commit.created, true); //created
      if (commit?.last_edited) { // edited
        commit.last_edited = formatDate(commit.last_edited, true);
      }
    });

    return newCommits;
  }

  validateFields = () => {
    const { activeCommit } = this.state;

    return validateNodeFields(this.requiredFields, activeCommit);
  }


  // ------------------------------------
  // 05. Render Methods
  // ------------------------------------
  renderCommitSubMenu = () => {
    const { loading } = this.state;
    const { canEdit, skaylAdmin } = this.props;
    const disabled = loading || !canEdit ? " disabled" : "";
  
    return (
      <ManageSubMenu skaylAdmin={skaylAdmin}>
        <KbButton />

        {loading &&
          <img id="loading-spinner"
              style={{ width: 30 }}
              src={loadingIcon} /> }

        <button id="form-action-new"
                  className="fas fa-plus"
                  title="New"
                  disabled={disabled}
                  onClick={this.handlePlus} /> 

        <button id="form-action-rollback"
                  className="fas fa-arrows-turn-to-dots"
                  title="Rollback To Commit"
                  disabled={disabled}
                  onClick={this.handleRollback} />

        <button id="form-action-save"
                className="fas fa-save"
                title="Save"
                disabled={disabled}
                onClick={this.handleSave} />
      </ManageSubMenu>
    );
  }

  renderPaneContent = () => {
    const {activeCommit, originalCommit, isNewCommit} = this.state
    if (!activeCommit || !this.listViewRef.current) return;

    return (
      <div className="p-col">
        <PhenomInput label={(isNewCommit ? "New " : "" ) + "Commit Name"}
          // disabled={disabled}
          autoFocus={true}
          value={activeCommit && activeCommit.name}
          originalValue={!isNewCommit && originalCommit ? originalCommit.name : undefined}
          onClickResetIcon={() => this.updateActiveCommit("name", originalCommit.name)}
          onChange={(e) => this.updateActiveCommit("name", e.target.value)} 
          config={this.requiredFields["name"]}
        />
        <PhenomTextArea label="Description"
          // disabled={disabled}
          value={activeCommit && activeCommit.description}
          originalValue={!isNewCommit && originalCommit ? originalCommit.description : undefined}
          onClickResetIcon={() => this.updateActiveCommit("description", originalCommit.description)}
          onChange={(e) => this.updateActiveCommit("description", e.target.value)} 
        />

        <div className="p-row">
          {activeCommit?.author && <div className="p-col" style={{gap: 0}}>
              <PhenomLabel text="Author"/>
              <p>{activeCommit.author}</p>
            </div>}
          {activeCommit.created &&  <div className="p-col" style={{gap: 0}}>
            <PhenomLabel text="Commit Date"/>
            <p>{activeCommit.created}</p>
          </div>}
          {activeCommit?.last_edited && <div className="p-col" style={{gap: 0}}>
            <PhenomLabel text="Edited Date"/>
            {activeCommit.last_edited || null}
          </div>}
        </div>
      </div>
    );
  }

  render() {
    const { commits, isNewCommit, activeCommit } = this.state;

    return ( 
      <>
        { this.renderCommitSubMenu() }
        <div className="phenom-content-wrapper">
          <div style={{margin: 20, overflowY: "hidden", height: "100%"}}>
            <ListPaneView
                ref={this.listViewRef}
                /* Data */
                mainKey={"id"}
                activeItem={activeCommit}
                /* List */
                lists={[
                  { collapsible: false,
                    data: commits,
                    columns: [{header: "Commits", key: "name", subKey: "description", flex: 4}, 
                              {header: "Author", key: "author", flex: 1}, 
                              {header: "Commit Date", key: "created", flex: 2}]
                  }
                ]}
                /* Pane */
                renderPaneContent={this.renderPaneContent}
                /* Config */
                onSelect={(item) => {
                  this.setActiveCommit(item);
                }}
            />
          </div>
        </div>
      </>
    );
  }
}

const msp = (state) => ({
  userIdentity: state.user.userIdentity,
  canEdit: state.user.canEdit,
  skaylAdmin: state.user.skaylAdmin,
});

export default connect(msp)(Commit);

