import Pull from "./branch/pull";
import Push from "./branch/push";
import Approve from "./branch/approve";
import ModelManager from "./branch/model_manage";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { _ajax } from "../../requests/sml-requests";
import { routeUrlBuilder } from "../../routes";
import Commit from "./branch/edit-commits";

class Branch extends React.Component {
    render() {
        const { skaylAdmin } = this.props;
        return (
            <>
                <Switch>
                  <Route path={routeUrlBuilder("manage", "branch", "model-manage")} 
                         component={ModelManager} />
                  <Route path={routeUrlBuilder("manage", "branch", "push")}  
                         component={(props) => <Push tree={this.props.tree} {...props}/>}/>
                  <Route path={routeUrlBuilder("manage", "branch", "pull")}  
                         component={(props) => <Pull tree={this.props.tree} {...props}/>}/>
                  <Route path={routeUrlBuilder("manage", "branch", "approve")}  
                         component={(props) => <Approve tree={this.props.tree} {...props}/>}/>
                  {skaylAdmin && <Route path={routeUrlBuilder("manage", "branch", "commit")}  
                         component={Commit}/>}
                  <Redirect to="/manage/branch/model-manage" />
                </Switch>
            </>
        );
    }
}

// used by Subscription and user_pmodel_manager
export function fetchModelTree(nested = true, passedUser = false) {
    return _ajax({
      url: "/index.php?r=/sub-model/users-index-tree",
      method: "post",
      data: {passedUser: passedUser}
    }).then((res) => {
        function traverse(node, head, parent, treeBranch, family) {
            let thisFamily;
            if (parent) {
                thisFamily = family;
            } else {
                thisFamily = [];
            }

            if (node.children) {
                Object.entries(node.children).forEach((e) => {
                    let newBranchId;
                    if (nested) {
                        newBranchId = treeBranch.push({
                            text: e[1][0]["branchName"],
                            id: e[1][0]["branchId"],
                            children: [],
                            type: "branch"
                        });
                    }
                    traverse(e[1], e[0], node.name, nested ? treeBranch[newBranchId - 1].children : treeBranch, thisFamily);
                    thisFamily.push(e[0]);
                });
            } else {
                Object.entries(node).forEach((e) => {
                    const leaf = e[1];
                    const newBranchId = treeBranch.push({
                        text: leaf.name,
                        children: [],
                        type: "model",
                        id:leaf.id,
                        additionalData: {
                            permissions: leaf.permissions,
                            dependencies: JSON.parse(leaf.dependencies),
                            id: leaf.id,
                            family: thisFamily,
                            created: leaf.created,
                            description: leaf.description
                        }
                    });

                    traverse(leaf, null, head, nested ? treeBranch[newBranchId - 1].children : treeBranch, thisFamily);
                    thisFamily.push(leaf.name);
                });

                const tailLeaf = treeBranch.shift();
                treeBranch.push(tailLeaf);
            }
        }

        const tree = [];
        traverse({children: Object.values(res.data)}, null, null, tree, null);
        return tree;
    });
}


const msp = (state) => ({
  expired: state.user.expired,
  skaylAdmin: state.user.skaylAdmin,
})


export default connect(msp)(Branch)