/**
 * INDEX
 * ------------------------------------------------------------
 * 01. Life Cycle Methods
 * 02. Getter Methods
 * 03. Setter Methods
 * 04. Render Methods
 * ------------------------------------------------------------
 */

import React from "react";
import { ListView } from "@progress/kendo-react-listview";
import { Button } from "@progress/kendo-react-buttons";
import { PhenomInput, PhenomTextArea } from "../util/stateless";
import { ResizeBarHorizontal } from '../util/stateless';

/**
 * `ListPaneView` Component Usage:
 *
 * Renders a list of objects with a customizable pane.
 * Requires at least one list, with one column.
 *
 * Data Props:
 *   - mainKey (String): Specifies the unique identifier key for list item selection.
 *   - activeItem (Object): Represents the currently selected item in the list.
 *
 * List Props:
 *   - listWidth (Number): Sets a fixed width for the list portion of the component.
 *   - renderContentAboveList (Function): Renders custom content above the list.
 *   - lists (Array): List objects each with their own data and options. List object:
 *       - collapsible (Boolean, optional): Indicates if the list section can be collapsed.
 *       - collapsed (Boolean, optional): The initial collapsed state of the list section.
 *       - deleteable (Boolean, optional): Allows deletion of items from the list.
 *       - headerOnly (Boolean, optional): When true, only the header of the list is displayed.
 *       - data (Array): Data items to be displayed in the list.
 *       - columns (Array): Defines the columns for the list, with options for primary display, additional data, and layout customization:
 *            - header (String): Title of the column.
 *            - key (String): Key from the data object to display as primary data.
 *            - subKey (String, optional): Key for displaying secondary data beneath the primary data.
 *            - tagKey (String, optional): Key for displaying tags alongside the primary data.
 *            - flex (Number, optional): Flex grow factor to control column width.
 *
 * Pane Props:
 *   - renderPaneContent (Function): Returns the components to render within the detail pane.
 *   - minimizePane (Boolean, optional): Controls whether the detail pane can be minimized when there is no active item.
 *
 * Config Props:
 *   - onSelect (Function): Handler called when an item is selected, useful for setting the active item.
 *   - onDelete (Function): Handler for when an item is deleted from the list.
 *
 * This component requires a structured data source and interaction handling to manage items effectively within a user interface.
 */

export class ListPaneView extends React.Component {
    constructor(props) {
        super(props);

        const listStates = {};
        props.lists.forEach((list, idx) => { //set any internal list states here
            listStates["list" + idx] = { 
                collapsed: list.collapsible ? 
                    (list?.collapsed === undefined || list.collapsed ) ? true : false 
                    : false,
                deleteable: list.deleteable ? true: false,
            }
        });
    
        this.state = {
            listStates: listStates,
        }
      }


    // ------------------------------------
    // 01. Life Cycle Methods
    // ------------------------------------
    componentDidMount() {
    }
  
    componentDidUpdate(prevProps, prevState) {
    }
  
    componentWillUnmount() {
    }


    // ------------------------------------
    // 03. Getter Methods
    // ------------------------------------
    getIsNewItem = () => {
        return this.state.isNewItem;
    }

    getActiveItem = () => {
        return this.state.activeItem;
    }

    
    // ------------------------------------
    // 04. Setter Methods
    // ------------------------------------
    setListState = (idx, key, value) => {
        this.setState(prevState => ({
            listStates: {
                ...prevState.listStates,
                ["list" + idx]: {
                    ...prevState.listStates["list" + idx],
                    [key]: value
                }
            }
        }));
    }

    getListState = (idx, key) => {
        const listState = this.state.listStates["list" + idx];
        return listState ? listState[key] : undefined;
    }

    
    // ------------------------------------
    // 04. Render Methods
    // ------------------------------------
    renderHeader = (props, list, idx) => {
        const { columns, data, headerOnly, collapsible } = list;
        const { onDelete, onCheck } = this.props;
        if (!columns.length) return null;

        const collapsed = this.getListState(idx, "collapsed") || !data?.length;

        let headerClass = '';
        if (idx === 0) headerClass = 'main';

        return (
            <div className={"list-view-header " + headerClass} onClick={() => collapsible && this.setListState(idx, "collapsed", !collapsed)}>
                <div className={"list-view-header-main-column " + headerClass} key={0} style={{ flex: columns[0].flex || 1}}>
                    {(collapsible || onCheck) && !headerOnly &&
                        <div className={"list-view-header-column " + headerClass} style={{ flex: 1, alignItems: "center", justifyContent: "center", display: "flex" }} > 
                            {collapsible &&
                                <span className={collapsed ? "fas fa-chevron-right" : "fas fa-chevron-down"}
                                    style={{ color: 'rgb(86, 86, 86)', width: "15px", marginRight: "5px"}} 
                                />
                            }
                        </div>
                    }
                    <p>{columns[0].header} {"(" + (data?.length || 0) + ")"}</p>
                </div>
                {columns.slice(1).map((column, index) => (
                    <div className={"list-view-header-column " + headerClass} key={index} style={{ flex: column.flex || 1}}>
                        {!collapsed && <p>{column.header}</p>}
                    </div>
                ))}
                {onDelete && !headerOnly &&
                    <div className={"list-view-header-column " + headerClass} style={{ flex: 1 }} />
                }
            </div>
        );
    }
    
    renderItem = (props, list, idx) => {
        const { columns, data, headerOnly } = list; 
        const { activeItem, mainKey, onSelect, onCheck, onDelete } = this.props;
        const collapsed = this.getListState(idx, "collapsed");
        const deleteable = this.getListState(idx, "deleteable");

        if (!columns.length || collapsed || headerOnly) return null;

        const selected = activeItem && activeItem[mainKey] === props.dataItem[mainKey] ? "selected" : "";

        return (
            <div className={"list-view-row " + selected} 
                 onClick={(e) => {
                    onSelect && onSelect(props.dataItem);
                }}
                key={props.dataItem[mainKey]}
            >
                {/* render rest of columns */}
                {onCheck &&
                    <div className="p-col list-view-row-column" style={{ flex: 1 }} >
                        <input type="checkbox" style={{width: "16px", height: "16px"}} 
                        onClick={(e) => {
                            e.stopPropagation();
                            return onCheck(e, props.dataItem);
                        }}/>
                    </div>
                }
                {/* render first column separately, can have descriptions */}
                <div key={0} className="p-col list-view-row-main-column" style={{ flex: columns[0].flex || 1 }}>
                    <div className="p-row" style={{ gap: 0, flexWrap: "nowrap", alignItems: "center" }}>
                        {props.dataItem[columns[0]?.tagKey] && 
                            <p style={{ margin: 0, width: "fit-content", minWidth: "fit-content", marginRight: "5px", marginBottom: "2px"}}>
                                {"[" + props.dataItem[columns[0]?.tagKey] + "] "}
                            </p>}
                        <h3 style={{ margin: 0, width: "fit-content", textTransform: "none"}}>
                            {props.dataItem[columns[0].key]}
                        </h3>
                    </div>
                    {columns[0]?.subKey && 
                        <div className="p-row" style={{minWidth: 0}}>
                            <p>{props.dataItem[columns[0]?.subKey] || ""}</p>
                        </div>}
                </div>
                {/* render rest of columns */}
                {columns.slice(1).map((column, index) => (
                    <div key={index} className="p-col list-view-row-column" style={{ flex: column.flex || 1}}>
                        <p>{props.dataItem[column.key]}</p>
                    </div>
                ))}
                {/* render rest of columns */}
                {onDelete &&
                    <div className="p-col list-view-row-column" style={{ flex: 1}} >
                        {deleteable && <Button iconClass="fas fa-trash" look="bare" title="Delete"  style={{fontSize: 18, height: "21px", width: "21px"}}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    return onDelete(props.dataItem);
                                }}/>}
                    </div>
                }
            </div>
        );
    }

    handleHorizontalResize = (mouse) => {
        const list = document.querySelector("#resizeable-list");
        const rect = list.getBoundingClientRect();
        const newWidth = rect.width - mouse;
        list.style.width = newWidth + "px";
      }

    render() {
        const { renderPaneContent, renderContentAboveList, lists, minimizePane, listWidth } = this.props;
        const { listStates } = this.state;

        return (
            <div style={{display: 'flex', height: "100%"}}>
                {/* List */}
                <div id="resizeable-list" style={{width: minimizePane ? "100%" : listWidth ? listWidth + "%" : "60%", display: "flex", flexDirection: "column", height: "100%", minWidth: "30%"}}>
                    <div className="phenom-content-scrollable" 
                         style={{ flexGrow: 1, overflow: "hidden" }}>
                        {/* Optional Content Above List */}
                        {!!renderContentAboveList ? renderContentAboveList() : null}
                        {/* List(s) - (one list & one column required) */}
                        {lists.map((list, idx) => {
                            return (
                                <ListView
                                    key={idx}
                                    data={list.data}
                                    item={(item) => this.renderItem(item, list, idx)}
                                    header={(item) => this.renderHeader(item, list, idx)}
                                    style={{ border: "none" }}
                                /> 
                            );
                        })}
                    </div>
                </div>

                {/* Can hide pane by passing in minimizePane*/}
                {!minimizePane && <>
                    <ResizeBarHorizontal onResize={this.handleHorizontalResize} style={{marginLeft: "20px"}} />
                
                    {/* Pane */}
                    <div className="phenom-content-scrollable" style={{ display: "flex", flexDirection: "column", height: "100%", flexGrow: 1, minWidth: "25%" }}>
                        <div style={{ display: "flex", flexDirection: "column", gap: "1em", paddingLeft: "20px", flex: 1, marginRight: 5 }}>
                            {/* Pane Content */}
                            {(!!renderPaneContent && renderPaneContent()) || <div style={{flexGrow: 1}} />}
                        </div>
                    </div>
                </>}
            </div>
        )
    }
}

export default ListPaneView;
