import React from "react";
import { connect } from "react-redux";
import { PhenomLabel } from "../../util/stateless";
import {MergeErrorConfirm} from "./merge_error_confirm"
import LoaderButton from "../../widget/LoaderButton";
import PhenomId from "../../../requests/phenom-id";
import { ManageSubMenu } from "./model_manage";
import ChangeSetManager from "./sections/ChangeSetManager";
import NavTree from "../../tree/NavTree";
import * as actionCreators from "../../../requests/actionCreators";
import { KbButton } from "../../util/stateless";
import MergeSummary from "./merge_summary";

import { Checkbox } from "@progress/kendo-react-inputs";
import { validateNodeFields } from "../../util/util";
import { createReviewProject, isProjectLocked } from "../../../requests/sml-requests";
import ReactTooltip from "react-tooltip";
import { PhenomInput, PhenomTextArea } from "../../util/stateless";
import { cloneDeep } from "lodash";


class Pull extends React.Component {
    
    summaryRef = React.createRef();

    constructor(props) {
        super(props);

        this.newReviewProject = {
          name: "",
          description: "",
          lockDstModel: true,
          requestId: null,
        }

        this.defaultState = {
          loading: false,
          contentOverride: false,
          deprecateDeletes: false,
          deprecateMoves: false,

          isReviewProject: false,
          lockable: false,
          reviewProject: {...this.newReviewProject}
        };

        this.state = cloneDeep(this.defaultState);

        this.noticeRef = undefined;
        this.parentBranchName = undefined;
        this.branchName = undefined;
        this.neededNodes = undefined;

        this.requiredFields = {
          ["name"]: {
            required: true,
            checkFirstChar: true,
            checkAllChars: true,
            errorRef: React.createRef(),
          },
        }
    }

    componentDidMount() {
      NavTree.collapseNavTree(false);
      this.setLockable();
      ReactTooltip.rebuild();
    }

    setLockable = () => {
      const {userIdentity, skaylAdmin} = this.props;
      if(!skaylAdmin) return;

      return isProjectLocked(userIdentity.branchId).then(res => {
        const disableLock = res.data?.locked_status;

        this.setState({lockable: !disableLock});
      });
    }

    handleSelectAll = () => {
      NavTree.selectAllMergeCandidates();
    };

    selectNecessaryNodes = () => {
      NavTree.selectMergeCandidates( this.neededNodes.map(n => n.guid) );
    }
  
    selectSingleNode = (guid) => {
      NavTree.selectMergeCandidates([ guid ]);
    }

    toggleDeleteDeprecation = () => {
      this.setState({ deprecateDeletes: !this.state.deprecateDeletes})
    }

    toggleMoveDeprecation = () => {
      this.setState({ deprecateMoves: !this.state.deprecateMoves })
    }

    handlePull = () => {
      this.setState({loading: true});

      const rejected = (errText) => {
        this.setState({loading: false});
        typeof errText === "string" && actionCreators.receiveErrors(errText);
      }
  
      const resolved = (response) => {
        if (response.status === "success") {
          actionCreators.receiveLogs("Pull Completed");
          this.summaryRef.current.fetchMergeNodes();
          this.setState({...this.defaultState});
        } else {
          if(response.errors.length) {
            if (typeof response.errors[0] === 'string') {
              actionCreators.receiveErrors(response.errors);
            } else {
              const fixables = ["references", "unreachable"];
              const allFixable = response.errors.every(err => fixables.includes(err.method));

              if(allFixable) {
                this.setNeededNodes(response.errors);
                MergeErrorConfirm.show(this.neededNodes, this.selectNecessaryNodes, this.selectSingleNode);
              } else {
                actionCreators.receiveErrors(Array.from(new Set(response.errors.map(err => err.text))));
              }
            }
          } else {
            actionCreators.receiveErrors(["Failed to perform merge."]);
          }
        }
      }
  
      return NavTree.pull(this.state.deprecateDeletes, this.state.deprecateMoves).then(resolved, rejected);
    }

    // this logic is from the original code
    getAbsentParents = () => {
      NavTree.getSelectedMergeCandidates().forEach(leaf => {
        const parentLeaf = leaf.getParentLeaf();

        if (parentLeaf && parentLeaf.isMergeCandidate() && !parentLeaf.isMarkedForMerge()) {
          const node = {};
          node["name"] = parentLeaf.getName();
          node["guid"] = parentLeaf.getGuid();
          node["error_type"] = "PARENT_ABSENT";
          node["error_name"] = leaf.getName();
          node["error_guid"] = leaf.getGuid();
          node["failure"] = false
          this.neededNodes.push(node);
        }
      })
    }

    // this logic is from the original code
    //    - "neededNode" is a guid
    setNeededNodes(response) {
      this.neededNodes = [];
      response.filter(er => er.method === "references" || er.method === "unreachable").forEach((err, i) => {
          const neededGuid = err.value;
          const neededLeaf = NavTree.getLeafNode(neededGuid);

              const failure = !neededLeaf;
              const node = {};
              node["name"] = !failure ? neededLeaf.getName() : "Not Found";
              node["guid"] = neededGuid;
              node["error_type"] = err.method
              node["error_name"] = err.name;
              node["error_guid"] = err.guid;
              node["failure"] = failure;
              node["deleted"] = neededLeaf?.isDeletedForMerge();
              this.neededNodes.push(node);
      })
    }
    
    validateFields = () => {
      const { reviewProject, isReviewProject } = this.state;
      if(!isReviewProject) return true;
  
      return validateNodeFields(this.requiredFields, reviewProject);
    }
  
    handleCreateReviewProject = () => {
      if (!this.validateFields()) return actionCreators.receiveWarnings("Please fill in the missing fields.");
      const { reviewProject, activeRequest } = this.state;
  
      const requestMap = NavTree.getRequestMap();
      if (!requestMap) return actionCreators.receiveErrors("Please make a selection and try again.");

      this.setState({loading: true});
  
      const reviewProject2 = {
        ...reviewProject,
        requestId: activeRequest?.id,
        merges: requestMap,
      }

      return createReviewProject(reviewProject2).then((res)=> {
        actionCreators.receiveResponse(res);
        this.setState({
          reviewProject: {...this.newReviewProject},
          isReviewProject: false,
        });
        this.setLockable();
      }).always(() => {
        this.setState({loading: false});
      });
    }

    updateReviewProject = (key, value) => {
      this.setState(prevState => ({
          reviewProject: {
              ...prevState.reviewProject,
              [key]: value
          }
      }));
    }

    render() {
      const { userIdentity, skaylAdmin } = this.props;
      const { reviewProject, isReviewProject, lockable, loading } = this.state;
      const phenomId = new PhenomId("pull", this.props.idCtx);
      const cinc_works = this.props.userRole.indexOf('c') !== -1;
    
      return (
        <div className="phenom-content-wrapper">
          <ManageSubMenu skaylAdmin={skaylAdmin}>
            <KbButton />
          </ManageSubMenu>
          <MergeErrorConfirm idCtx={phenomId.gen("init")} />
    
          <div id={phenomId.gen("", "wrapper")} className="branch-wrapper" style={{ flexGrow: 1, overflowY: "auto" }}>
            <PhenomLabel text="Pull" />
            <p>Select the nodes to pull from the nav tree on the left and then click Pull to pull from the parent branch. Pulling is immediate and will overwrite your current nodes. This action cannot be undone.</p>
    
            <div className="p-row">
              <div className="p-col p-col-1">
                <div className="p-col" style={{ gap: 7, marginBottom: "5px", color: "#565656" }}>
                  <div className="p-row">
                    <Checkbox label="Pull Deletions as Deprecations" checked={this.state.deprecateDeletes} onChange={this.toggleDeleteDeprecation} />
                  </div>
                  <div className="p-row">
                    <Checkbox label="Pull Node Moves as Deprecations" checked={this.state.deprecateMoves} onChange={this.toggleMoveDeprecation} />
                  </div>
                  {skaylAdmin && (
                    <div className="p-row" style={{ gap: 0, alignItems: "center" }}>
                      <Checkbox label="New Review Project" checked={isReviewProject} onChange={(e) => this.setState({ isReviewProject: e.value })} />
                      <div style={{ display: "flex" }}>
                        <span className="fas fa-info-circle"
                                  style={{margin: "0px 0px 2px 5px"}}
                                  data-tip={`
                                    A review project is a temporary project allowing you to review the impacts of the merge before finalizing it.
                                    <br/><br/>
                                    Like any other projects, you can access it from the "Manage Projects" page or from the merge request it originated from.
                                    <br/><br/>
                                    When creating a review project, you have the ability to lock your current project (i.e., the project you are merging into).
                                    This will prevent users from making any changes to it for the duration of the review. The project will be unlocked when you finalize the merge. 
                                    <br/>
                                    Note that it is possible to have multiple review projects (from different merge requests) based on the same project.
                                    Your project may be locked but it doesnt restrict the creation of additional review projects.`}
                                  data-for="hoverTip"
                                  data-html={true}
                                  data-place="right"/>
                        <ReactTooltip id='hoverTip'/>
                      </div>
                    </div>
                  )}
    
                  {isReviewProject && (
                    <div style={{ marginLeft: "20px", gap: 5 }}>
                      <div className="p-row" style={{ gap: 0, alignItems: "center" }}>
                        <Checkbox label="Lock Current Project During Review" checked={!lockable || reviewProject.lockDstModel} onChange={(e) => this.updateReviewProject("lockDstModel", e.value)} disabled={!lockable} />
                        <span className="fas fa-info-circle"
                                  style={{margin: "0px 0px 2px 5px"}}
                                  data-tip={lockable ? `
                                    Locking a project during the review process is strongly recommended to prevent any modifications to the merge destination.
                                    This ensures that the destination project remains unchanged throughout the review. 
                                    <br/><br/>
                                    If changes are made while the destination project is under review, a content rebase will be necessary before finalizing the merge.
                                    <br/><br/>
                                    Once a project is locked, it cannot be locked again until the review is completed.
                                    `
                                    :
                                    `The current project is already locked.`}
                                  data-for="lock-info"
                                  data-html={true}
                                  data-place="right"/>
                        <ReactTooltip id='lock-info' className="tool-tip"/>
                      </div>
                      <div className="p-col">
                        <PhenomInput
                          label={"Review Project Name"}
                          containerProps={{ style: { marginTop: "5px" } }}
                          autoFocus={true}
                          value={reviewProject.name}
                          onChange={(e) => this.updateReviewProject("name", e.target.value)}
                          config={this.requiredFields["name"]}
                        />
                        <PhenomTextArea
                          label="Description"
                          className
                          containerProps={{ style: { marginBottom: "10px" } }}
                          style={{ minHeight: "50px" }}
                          value={reviewProject.description}
                          onChange={(e) => this.updateReviewProject("description", e.target.value)}
                        />
                      </div>
                    </div>
                  )}
    
                  <div className="p-row" style={{ gap: 0 }}>
                    <LoaderButton onClick={this.handleSelectAll} disabled={cinc_works} text="SELECT ALL" style={{ marginRight: "5px" }} />
                    <LoaderButton
                      text={isReviewProject ? "CREATE REVIEW PROJECT" : "PULL CHANGES"}
                      onClick={() => isReviewProject ? this.handleCreateReviewProject() : this.handlePull()}
                      disabled={loading || cinc_works}
                      style={{ marginLeft: "0" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <MergeSummary page={"pull"} ref={this.summaryRef} />
            {/* <div className="branch-wrapper" style={{ flex: 1, display: "flex", flexDirection: "column", overflow: "hidden" }}>
                    <PhenomLabel text="Pull Change Sets" style={{ marginBottom: 10 }} />
                    <ChangeSetManager mergeMode="pull" />
                  </div> */}
          </div>
        </div>
      );
    }
}



const msp = (state) => ({
  userRole: state.user.userRole,
  userIdentity: state.user.userIdentity,
  skaylAdmin: state.user.skaylAdmin,
})


export default connect(msp)(Pull)
